<template>
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="getAgenda(null)">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-7 col-lg-5 col-md-5 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                  :helper-text="$t('general.search')"
                  :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')"
                  title="general.search"
                ></input-filter>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click="getAgenda"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12">
      <dashboard-box :title="$t('general.packing_list')">
        <template v-slot:preview>
          <data-table
            :bordered="false"
            :borderless="true"
            :no-border-collapse="true"
            :hover="false"
            :outlined="false"
            :striped="false"
            tableVariant="''"
            :fields="fields"
            :items="formattedItems"
            @hitBottom="getAgenda"
            @rowClicked="onRowClicked"
          >
          </data-table>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
} from "@/core/services/store/REST.module";
import {
  EXPORT,
  EXPORT_PDF,
} from "@/core/services/store/following-production/following_production.module";
import moment from "moment";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import DataTable from "@/assets/components/dataTable/DataTable";

import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import "vue-simple-calendar/static/css/gcal.css";
// TODO: remove import and delete file
import { CalendarMathMixin, CalendarView, CalendarViewHeader } from "vue-simple-calendar"; // published version
import Vue from "vue";

import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {FOLLOWING_PRODUCTION_ORDER_END_DATE, FOLLOWING_PRODUCTION_ORDER_START_DATE} from "@/core/storage/storage-names"

export default {
  name: "FollowingProductionCalendar",
  components: {
    TextInput,
    InputFilter,
    DashboardBox,
    DataTable,
    CalendarView,
    CalendarViewHeader,
    DateFilter,
    CustomMultiSelect,
    SelectFilter,
  },
  data() {
    return {
      filters: {
        search: null,
        start_date: moment().subtract(30, "d"),
        end_date: moment().add(1, "d"),
        per_page:25,
        page:1,
      },
      agendaItems: [],
      fields: [
        {
          key: "normal_image",
          label: this.$t("following.image"),
          sortable: false,
          class:
            "text-center text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "instruction_date",
          label: this.$t("following.instruction_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "order_number",
          label: this.$t("following.order_number"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "instruction_type",
          label: this.$t("following.instruction_type"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "start_date",
          label: this.$t("following.start_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "end_date",
          label: this.$t("following.end_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "dispatch_location",
          label: this.$t("following.dispatch_location"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "amount",
          label: this.$t("following.amount"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "actual",
          label: this.$t("following.actual"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
      ],
    };
  },
  computed: {
    formattedItems() {
      if (!this.agendaItems) return [];
      let temp = [];
      this.agendaItems.forEach((item) => {
        let dispatchLocations = [];
        dispatchLocations.push(item.order_expense_detail.dispatch_location.name);

        let id = item.order_expense_detail.id;
        let orderImage = item.order_expense_detail.order_expense.order.image_link;
        let orderExpenseId = item.order_expense_detail.order_expense_id;
        let orderId = item.order_expense_detail.order_expense.order_id;
        let instructionDate = moment(item.date, LARAVEL_DATE_FORMAT).format(
          MOMENT_SYSTEM_DATE_FORMAT
        );
        let instructionType =
          item.order_expense_detail.order_expense.expense.translations[0].name;
        let startDate = moment(item.date, LARAVEL_DATE_FORMAT).format(
          MOMENT_SYSTEM_DATE_FORMAT
        );
        let endDate = moment(item.date, LARAVEL_DATE_FORMAT).format(
          MOMENT_SYSTEM_DATE_FORMAT
        );
        let dispatchLocation =
          dispatchLocations.length === 0 ? "-" : dispatchLocations.join(",");
        let orderNumber = item.order_expense_detail.order_expense.order.order_number;
        let amount = item.target;
        let actual = item.actual;
        let expense = item.order_expense_detail.order_expense.expense;

        temp.push({
          id: id,
          order_expense_id: orderExpenseId,
          order_id: orderId,
          normal_image: orderImage,
          instruction_date: instructionDate,
          instruction_type: instructionType,
          start_date: startDate,
          end_date: endDate,
          dispatch_location: dispatchLocation,
          order_number: orderNumber,
          amount: amount,
          actual: actual || "-",
          expense: expense,
        });
      });

      return temp;
    },
  },
  methods: {
    getAgenda() {
      this.$store.commit(SET_LOADING, true);
      localStorage.setItem(FOLLOWING_PRODUCTION_ORDER_END_DATE, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_PRODUCTION_ORDER_START_DATE, moment(this.filters.end_date));
    

      let self = this;
      let filters = _.cloneDeep(self.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$store.commit(SET_LOADING, true);
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/orders/expenses/details/targets",
          filters: filters,
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            this.$store.commit(SET_LOADING, false);

            self.agendaItems = response.data;
          }
        });
    },
    resetFilters() {
      this.filters = {
        search: null,
        start_date: moment().subtract(30, "d"),
        end_date: moment().add(1, "d"),
      };
    },
    onRowClicked(item, index, event) {
      this.$router.push({
        name: "following_production.edit",
        params: {
          id: this.formattedItems[index].id,
        },
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.following_production"),
        route: "following_production.index",
      },
      { title: this.$t("general.dashboard") },
    ]);

    if (!this.isUserGranted("Staff", ["supplierCompanyStaff"], false, true)) {
      return false;
    }
    if (!this.filters) {
      this.resetFilters();
    }
    this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_ORDER_START_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_ORDER_START_DATE))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_ORDER_END_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_ORDER_END_DATE))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

    this.getAgenda();
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
};
</script>

<style scoped lang="scss"></style>
